<template>
  <div id="app">
    <div class="top-bar">
      <div class="topbar-inner">
        <img src="@/assets/logo.png" alt="logo" class="logo">
        <div class="menu-li">
          <div class="ml-item home"><a :href="iniData.zuolist.nv1" target="_blank">官方首页</a></div>
          <div class="ml-item gift" @click="showSearch = true">中奖查询</div>
          <div class="ml-item wei3"><a :href="iniData.zuolist.nv3" target="_blank">位三链接</a></div>
          <div class="ml-item serve"><a :href="iniData.zuolist.nv4" target="_blank">联系客服</a></div>
        </div>
      </div>
    </div>
    <div class="top-gongg">
    <div class="top-gongg-inner">
      <i class="icon-cclb"></i>
      <div class="lunleft">
        <ul>
          <li>{{iniData.news}}</li>
        </ul>
      </div>
    </div>
  </div>
    <div class="app-page">
      <div class="app-container">
        <div class="main-content">
          <div class="time-down" v-if="timeDownShow" :style="!hasTime ? 'marginBottom:10vw' : ''">
            <count-down :start-time="defaultTime.start_time" :end-time="defaultTime.end_time" v-on:start_callback="countDownS_cb(1)" v-on:end_callback="countDownE_cb(1)"></count-down>
          </div>
          <div class="account-container" v-if="!giftShow && hasTime">
            <h3 class="title-bar">登录会员账号</h3>
            <div class="field__value-screen">
              <input type="text" placeholder="账号" v-model="username" class="field__control-screen" />
              <button class="button--info-screen" @click="confirm">点击刮刮乐</button>
            </div>
          </div>
          <div class="ggl-container" v-if="giftShow">
            <button class="ggl-btn" @click="giftResl">点我刮奖</button>
            <div class="count-static">剩余<font color="#f79605"> {{countNum}} </font>次</div>
          </div>
          <div class="mq-list-container" v-if="isReady">
            <div class="mq-listwarp">
              <vue-seamless-scroll class="mq-listwarp-inner" :data="listData" :class-option="classOption">
                <ul class="mq-listitem">
                  <li v-for="(item, index) in listData" :key="index">
                    <span class="title">{{item.title}}</span>
                    <span class="date">{{item.date}}</span>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
        <div class="mml-container">
          <div class="mmlc-menu">
            <div class="mmlc-mitem" :class="showMenu == 1 ? 'active' : ''" @click="showMenuClick(1)">活动内容</div>
            <div class="mmlc-mitem" :class="showMenu == 2 ? 'active' : ''" @click="showMenuClick(2)">活动细则</div>
            <div class="mmlc-mitem" :class="showMenu == 3 ? 'active' : ''" @click="showMenuClick(3)">优惠规则与条款</div>
          </div>
          <div class="mmlc-content">
            <div class="mmlc-ctitle"></div>
            <div class="mmlc-citem" v-if="showMenu == 1" v-html="iniData.pcactcontent">
            </div>
            <div class="mmlc-citem" v-if="showMenu == 2" v-html="iniData.pcxzcontent">
            </div>
            <div class="mmlc-citem" v-if="showMenu == 3" v-html="iniData.pcyhcontent">
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>{{iniData.cright}}</p>
      </div>
    </div>
    <van-popup
      v-model="gglShow"
      :style="{ width: '488px', height: '215px' }"
      class="sc-popup"
    >
      <vue-scratch-card
        element-id='scratchWrap'
        :ratio=0.35
        :move-radius=30
        :start-callback=startCallback
        :clear-callback=clearCallback
        :resul-img="imgUrl"
        :cover-img="coveImgUrl"
        coverColor="#949494"
        v-if="gglShow"
        >
        <div slot='result'>
          {{giftText}}
        </div>
      </vue-scratch-card>
    </van-popup>
    <van-popup
      v-model="showSearch"
      closeable
      :style="{ width: '780px', height: '540px' }"
      @close="closeSearchPopup"
      class="popup-search-block">
      <div class="block--title">输入会员账号查询</div>
      <div class="mcain-content">
        <div class="search-content">
          <div class="s-c-i">
            <span class="x-t">会员账号：</span>
            <input type="text" placeholder="账号" v-model="queryname" class="field__control" />
          </div>
          <div class="s-c-b">
            <button class="button--info" @click="queryResl">查询</button>
          </div>
        </div>
        <div class="result-content">
          <ul class="rc-head">
            <li>红包金额</li>
            <li>领取时间</li>
            <li>是否派彩</li>
          </ul>
          <div class="rc-main" v-if="resl.length">
            <ul class="rc-body" v-for="item in resl">
              <li>{{item.amount}}</li>
              <li>{{item.createtime}}</li>
              <li>{{item.status === 1 ? '是' : '否'}}</li>
            </ul>
          </div>
          <div v-else style="font-size: 16px;color: #fff;text-align: center;height: 48px;line-height: 48px;">暂无记录！</div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import coveImg from '@/assets/cover.jpg'
import CountDown from 'vue2-countdown'
import vueSeamlessScroll from 'vue-seamless-scroll'
import $ from 'jquery'
import '@/js/demo.js'

export default {
  name: 'App',
  components: {
    CountDown,
    vueSeamlessScroll
  },
  data () {
    return {
      imgUrl: '',
      coveImgUrl: coveImg,
      showSearch: false,
      resl: [],
      defaultTime: {
        c_time: '',
        start_time: '',
        end_time: ''
      },
      iniData: {
        news: '',
        pcactcontent: '',
        pcxzcontent: '',
        pcyhcontent: '',
        mactcontent: '',
        cright:'',
        zuolist: {
          nv1: '#',
          nv2: '#',
          nv3: '#',
          nv4: '#',
        }
      },
      giftShow: false,
      username: '',
      queryname: '',
      timeDownShow: false,
      giftText: '',
      hasTime: false,
      showOverlay: false,
      gglShow: false,
      baseUrl: window.location.origin,
      showMenu: 1,
      listData: [],
      countNum: 0,
      classOption: {
        step: 0.5,
        limitMoveNum: 2,
        // hoverStop: true,
        direction: 1,
        singleWidth: 0,
        singleHeight: 0,
        waitTime: 1000
      },
      isReady: false
    }
  },
  created: function (){
    if((navigator.userAgent.toLowerCase().match(/(nokia|sony|ericsson|mot|samsung|htc|sgh|lg|sharp|sie-|philips|panasonic|alcatel|lenovo|iphone|ipod|blackberry|meizu|android|netfront|symbian|ucweb|windowsce|palm|operamini|operamobi|openwave|nexusone|cldc|midp|wap|mobile|micromessenger)/i))) {
        window.location.href='/wap/index.html';
    }
    this.initDefaultData();
  },
  methods: {
    initDefaultData () {
      var self = this;
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      Promise.all([
        self.initNavData(),
        self.initListData(),
        self.initTimeData()
      ]).then(([navData, listData, timeData]) => {
        self.$toast.clear();
        self.iniData = navData.data;
        self.listData = listData.data.rec;
        self.defaultTime = timeData.data;
        self.timeDownShow = true;
        self.isReady = true;
        self.$nextTick(function() {
            $(".lunleft").lunleftFn({time: 15});
            $(".luntop").luntopFn({time: 15});
        });
      }).catch(function (err) {
        self.$toast.clear();
      })
    },
    initTimeData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/gettime'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    initNavData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getindex'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    initListData () {
      var self = this;
      return new Promise(resolve => {
        this.axios({
          method: 'post',
          url: self.baseUrl + '/index/Index/getbhrec'
        }).then(function (res){
          resolve(res.data)
        })
      })
    },
    confirm () {
      var self = this;
      if (!this.username) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/checkuser',
        data: {
          username: self.username
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.giftShow = true
          self.countNum = res.data.data.times
        } else {
          self.$toast.fail(res.data.msg)
        }
      }).catch(function (err) {
        self.$toast.clear();
      })
    },
    queryResl () {
      var self = this;
      if (!this.queryname) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/query',
        data: {
          username: self.queryname
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.resl = res.data.data.list
        }
      }).catch(function (err) {
        self.$toast.clear();
      })
    },
    giftResl () {
      var self = this;
      if (!this.username) {
        this.$toast.fail('请输入账号')
        return
      }
      this.$toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      this.axios({
        method: 'post',
        url: self.baseUrl + '/index/Index/getpacket',
        data: {
          username: self.username
        }
      }).then(function (res){
        self.$toast.clear();
        if (res.data.code == 0) {
          self.gglShow = true
          self.giftText = res.data.msg
          self.countNum = res.data.data.times
        }
      }).catch(function (err) {
        self.$toast.clear();
      })
    },
    countDownS_cb() {
      this.hasTime = true
    },
    countDownE_cb() {
      this.hasTime = false
      this.giftShow = false
    },
    closeSearchPopup () {
      this.queryname = ''
      this.resl = []
    },
    startCallback () {},
    clearCallback () {},
    showMenuClick (args) {
      this.showMenu = args
    }
  }
}
</script>

<style>
.mq-list-container{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.mq-listwarp{
  width: 850px;
  background: url(./assets/mcrc.png) no-repeat;
  background-size: 100%;
  padding: 60px 80px;
}
.mq-listwarp-inner{
  height: 196px;
  overflow: hidden;
} 
.mq-listitem{
  overflow: hidden;
}
.mq-listitem li{
  width: 400px;
  color: #e8aa25;
  font-size: 19px;
  line-height: 30px;
  height: 30px;
  float: left;
  text-align: left;
  padding-right: 24px;
  overflow: hidden;
}
.mq-listitem li .date{
    padding-left: 10px; 
}
.mq-listitem .title{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
html{
  font-size: 14px;
}
body{
  background: #B00918;
}
#app{
  text-align: center;
}
.app-page{
  display: flex;
  flex-direction: column;
  background: url(./assets/pc/background.jpg) no-repeat 50% -88px;
}
.top-bar{
  background: #8d1d0f;
  height: 92px;
}
.topbar-inner{
  width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 auto;
        height: 96px;
}
.top-bar img{
  max-height: 100%;
  margin-left: 36px;
  max-height: 88px;
}
.menu-li{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ml-item{
  width: 130px;
  height: 36px;
  margin-right: 20px;
  font-size: 0;
  cursor: pointer;
}
.ml-item a{
  display: block;
  width: 100%;
  height: 100%;
}
.ml-item.home{
  background: url(./assets/pc/home.png) no-repeat 0 50%;
}
.ml-item.gift{
  background: url(./assets/pc/gift.png) no-repeat 0 50%;
}
.ml-item.wei3{
  background: url(./assets/pc/wei3.png) no-repeat 0 50%;
}
.ml-item.serve{
  background: url(./assets/pc/serve.png) no-repeat 0 50%;
}
.top-gongg{
  background: #6d0f0c;
  height: 50px;
}
.top-gongg-inner{
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  line-height: 50px;
}
.lunleft {
    position: relative;
    overflow: hidden;
    height: 50px;
    width: 100%;
}
.lunleft ul {
    position: absolute;
    left: 100%;
    top: 0px;
    display: table;
}
.lunleft ul li {
    display: table-cell;
    white-space: nowrap;
    line-height: 50px;
}
.icon-cclb{
  width: 27px;
  height: 27px;
  background: url(./assets/pc/cclb.png) no-repeat 50% 50%;
  margin-right: 10px;
}
.app-container{
  display: flex;
  position: relative;
  width: 1200px;
  margin: 0 auto;
  justify-content: center;
  flex-direction: column;
}
.time-down{
  color: #fff;
  font-size: 32px;
}
.time-clock{
  display: flex;
  justify-content: center;
  align-items: center;
}
.time-down .title{
  margin-bottom: 12px;
  letter-spacing: 2px;
  font-weight: 600;
}
.tc-item{
  display: flex;
  flex-direction: column;
  width: 72px;
  height: 67px;
  border: 3px solid #090101;
  font-size: 18px;
  border-radius: 7px;
  justify-content: center;
}
.tc-item + .tc-item{
  margin-left: 10px;
}
.tc-item .num{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #1f1d1e;
  font-size: 24px;
  font-weight: bold;
}
.tc-item .txt{
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #434242;
}
.main-content{
  margin-top: 300px;
  min-height: 420px;
}
.sc-popup{
  background: url(./assets/gglbg.png) no-repeat;
  background-size: 100% 100%;
  box-sizing: border-box;
  padding: 20px 27px;
}
.scratchCard{
  width: 433px!important;
  height: 174px!important;
}
.scratchCard canvas{
  left: 0;
  bottom: 0;
  width: 433px!important;
  height: 174px!important;
}
.scratchCard .result{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  color: #f00;
}
.scratchCard .pic{
  display: none;
}
.account-container{
  position: relative;
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ggl-container{
  margin-top: 22px;
  height: 148px;
}
.ggl-btn{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  border-radius: 42px;
  margin-top: 30px;
  font-size: 16px;
  width: 240px;
  height: 42px;
}

.count-static{
  font-size: 14px;
  color: #fff;
  margin-top: 5px;
}
.title-bar{
  margin: 0;
  color: #fff;
  font-weight: normal;
  font-size: 28px;
  margin-bottom: 25px;
  letter-spacing: 4px;
  font-weight: 600;
}
.field__value{
  margin-bottom: 10px;
}
.field__control{
  border: 0 none;
  background: #39100b;
  width: 302px;
  height: 36px;
  font-size: 18px;
  padding: 0 5px;
  margin-top: 10px;
  color: #fff;
}

.field__value-screen{
  width: 500px;
  height: 60px;
  background-color: #02183c;
  border-radius: 40px;
  display: flex;
}
.field__control-screen{
  border: 0 none;
  background: transparent;
  height: 60px;
  font-size: 18px;
  padding: 0 10px 0 20px;
  color: #fff;
  flex: 1
}
.button--info-screen{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  width: 168px;
  height: 60px;
  font-size: 20px;
  border-radius: 30px;
}
.button--info{
  background-image: linear-gradient(#f79605, #fef7c7 53%, #fcf97e);
  border: 0 none;
  color: #bb2c1c;
  width: 168px;
  height: 30px;
  font-size: 20px;
  border-radius: 30px;
  margin-top: 10px;
}
.popup-search-block{
  display: flex;
  flex-direction: column;
        border-radius: 10px 10px 0 0;
}
.block--title{
  background-image: linear-gradient(#dc722d, #cc2e1b);
  height: 48px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 16px;
  padding-left: 10px;
}
.mcain-content{
  background: #94040c;
  flex: 1;
  padding: 20px 15px;
}
.search-content{
  display: flex;
  color: #fff;
  font-size: 14px;
  justify-content: center;
}
.s-c-i{
  display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 8px;
}
.s-c-i .field__control{
  margin-top: 0;
  font-size: 16px;
}
.s-c-b .button--info{
  margin-top: 0;
  width: 120px;
  font-size: 16px;
}
.result-content {
  overflow: hidden;
}
.rc-main{
  height: 350px;
  overflow-y: scroll;
  width: 767px;
}
.rc-head, .rc-body{
  display: flex;
  font-size: 14px;
  color: #fff;
}
.rc-head li, .rc-body li{
  flex: 1;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rc-head li{
  margin-top: 10px;
  border: 1px solid #a5050f;
}
.mmlc-menu{
  display: flex;
  justify-content: space-around;
}
.mmlc-mitem{
  width: 260px;
  height: 100px;
  background: url(./assets/pc/bbtn.png) no-repeat;
  font-size: 18px;
  color: #991608;
  line-height: 70px;
  cursor: pointer;
}
.mmlc-mitem.active{
  background: url(./assets/pc/bbtnc.png) no-repeat;
  color: #ffe982;
}
.mmlc-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mmlc-ctitle{
  width: 100%;
  height: 38px;
  background: url(./assets/pc/xtitle.png) no-repeat;
  background-size: 100%;
}
.mmlc-citem{
  min-height: 100px;
}
.mmlc-citem p{
  color: #e8aa25;
  font-size: 16px;
  text-align: left;
  padding: 0 80px;
  line-height: 25px;
}
.mc-table{
  border-right: 2px solid #cf2222;
  border-bottom: 2px solid #cf2222;
  margin-top: 50px;
  width: 100%;
  table-layout:fixed;
}
.mc-table th, .mc-table td{
  border-left: 2px solid #cf2222;
  border-top: 2px solid #cf2222;
}
.mc-table th{
  background-color: #760303;
  height: 48px;
  color: #fffc04;
}
.mc-table td{
  word-wrap:break-word;
}
.mc-table p{
  color: #fff;
  padding: 0;
  text-align: center;
}
.copyright{
  margin-top: 65px;
  background-color: #6d0f0c;
  height: 92px;
}
.copyright p{
  color: #ec6864;
  line-height: 92px;
  font-size: 18px;
  margin: 0;
}
</style>
